@charset "UTF-8";
/*$border-radius-lg: 3rem !default;*/
.badge.-small {
  font-size: .75rem; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #5e5e5e;
  --gray-dark: #303030;
  --black: #000000;
  --tertiary: #5e5e5e;
  --primary: #00a4ed;
  --secondary: #e90081;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f2f2f2;
  --dark: #3c3c3c;
  --primary-pastel: rgba(0, 164, 237, 0.05);
  --secondary-pastel: rgba(233, 0, 129, 0.05);
  --primary-solidarissimo: #274c79;
  --secondary-solidarissimo: #e90081;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Oswald", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #3c3c3c;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00a4ed;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #006fa1;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #b3b3b3;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Oswald";
  font-weight: 500;
  line-height: 1.2;
  color: #3c3c3c; }

h1, .h1 {
  font-size: 3.9rem; }

h2, .h2 {
  font-size: 2.125rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.4rem; }

h5, .h5 {
  font-size: 1.2rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.125rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.4rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  padding-top: 0.6rem;
  display: block;
  font-size: 0.875rem;
  color: #5e5e5e; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #5e5e5e; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1b1b1b;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #1b1b1b; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3c3c3c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #3c3c3c;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e6fa; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ad0f6; }

.table-hover .table-primary:hover {
  background-color: #a0def8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a0def8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9b8dc; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f47abd; }

.table-hover .table-secondary:hover {
  background-color: #f7a0d0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f7a0d0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8f8f8; }

.table-hover .table-light:hover {
  background-color: #eeeeee; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eeeeee; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8c8c8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9a9a9a; }

.table-hover .table-dark:hover {
  background-color: #bbbbbb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bbbbbb; }

.table-primary-pastel,
.table-primary-pastel > th,
.table-primary-pastel > td {
  background-color: rgba(252, 254, 255, 0.734); }

.table-primary-pastel th,
.table-primary-pastel td,
.table-primary-pastel thead th,
.table-primary-pastel tbody + tbody {
  border-color: rgba(248, 253, 255, 0.506); }

.table-hover .table-primary-pastel:hover {
  background-color: rgba(227, 246, 255, 0.734); }
  .table-hover .table-primary-pastel:hover > td,
  .table-hover .table-primary-pastel:hover > th {
    background-color: rgba(227, 246, 255, 0.734); }

.table-secondary-pastel,
.table-secondary-pastel > th,
.table-secondary-pastel > td {
  background-color: rgba(255, 252, 254, 0.734); }

.table-secondary-pastel th,
.table-secondary-pastel td,
.table-secondary-pastel thead th,
.table-secondary-pastel tbody + tbody {
  border-color: rgba(254, 248, 252, 0.506); }

.table-hover .table-secondary-pastel:hover {
  background-color: rgba(255, 227, 246, 0.734); }
  .table-hover .table-secondary-pastel:hover > td,
  .table-hover .table-secondary-pastel:hover > th {
    background-color: rgba(255, 227, 246, 0.734); }

.table-primary-solidarissimo,
.table-primary-solidarissimo > th,
.table-primary-solidarissimo > td {
  background-color: #c3cdd9; }

.table-primary-solidarissimo th,
.table-primary-solidarissimo td,
.table-primary-solidarissimo thead th,
.table-primary-solidarissimo tbody + tbody {
  border-color: #8fa2b9; }

.table-hover .table-primary-solidarissimo:hover {
  background-color: #b3c0cf; }
  .table-hover .table-primary-solidarissimo:hover > td,
  .table-hover .table-primary-solidarissimo:hover > th {
    background-color: #b3c0cf; }

.table-secondary-solidarissimo,
.table-secondary-solidarissimo > th,
.table-secondary-solidarissimo > td {
  background-color: #f9b8dc; }

.table-secondary-solidarissimo th,
.table-secondary-solidarissimo td,
.table-secondary-solidarissimo thead th,
.table-secondary-solidarissimo tbody + tbody {
  border-color: #f47abd; }

.table-hover .table-secondary-solidarissimo:hover {
  background-color: #f7a0d0; }
  .table-hover .table-secondary-solidarissimo:hover > td,
  .table-hover .table-secondary-solidarissimo:hover > th {
    background-color: #f7a0d0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #303030;
  border-color: #434343; }

.table .thead-light th {
  color: #474747;
  background-color: #e6e6e6;
  border-color: #ccc; }

.table-dark {
  color: #fff;
  background-color: #303030; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #434343; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #474747;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #474747;
    background-color: #fff;
    border-color: #6ed2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
  .form-control::placeholder {
    color: #5e5e5e;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e6e6e6;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #474747;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.4; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.4;
  color: #3c3c3c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #b3b3b3; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.35em + 0.1875rem);
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23303030' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.4em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.35em + 0.1875rem);
  background-size: calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.4em + 0.75rem);
  background-position: top calc(0.35em + 0.1875rem) right calc(0.35em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23303030' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.7em + 0.375rem) calc(0.7em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #3c3c3c;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #3c3c3c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #00a4ed;
  border-color: #00a4ed; }
  .btn-primary:hover {
    color: #fff;
    background-color: #008ac7;
    border-color: #0081ba; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 178, 240, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00a4ed;
    border-color: #00a4ed; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0081ba;
    border-color: #0078ad; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 178, 240, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #e90081;
  border-color: #e90081; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #c3006c;
    border-color: #b60065; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 38, 148, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #b60065;
    border-color: #a9005e; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 38, 148, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #1b1b1b;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #1b1b1b;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 168, 10, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1b1b1b;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #1b1b1b;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 168, 10, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #1b1b1b;
  background-color: #f2f2f2;
  border-color: #f2f2f2; }
  .btn-light:hover {
    color: #1b1b1b;
    background-color: #dfdfdf;
    border-color: #d9d8d8; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(210, 210, 210, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #1b1b1b;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #1b1b1b;
    background-color: #d9d8d8;
    border-color: #d2d2d2; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(210, 210, 210, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #3c3c3c; }
  .btn-dark:hover {
    color: #fff;
    background-color: #292929;
    border-color: #232222; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #3c3c3c; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #232222;
    border-color: #1c1c1c; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 89, 89, 0.5); }

.btn-primary-pastel {
  color: #fff;
  background-color: rgba(0, 164, 237, 0.05);
  border-color: rgba(0, 164, 237, 0.05); }
  .btn-primary-pastel:hover {
    color: #fff;
    background-color: rgba(0, 138, 199, 0.05);
    border-color: rgba(0, 129, 186, 0.05); }
  .btn-primary-pastel:focus, .btn-primary-pastel.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 243, 253, 0.5); }
  .btn-primary-pastel.disabled, .btn-primary-pastel:disabled {
    color: #fff;
    background-color: rgba(0, 164, 237, 0.05);
    border-color: rgba(0, 164, 237, 0.05); }
  .btn-primary-pastel:not(:disabled):not(.disabled):active, .btn-primary-pastel:not(:disabled):not(.disabled).active,
  .show > .btn-primary-pastel.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 129, 186, 0.05);
    border-color: rgba(0, 120, 173, 0.05); }
    .btn-primary-pastel:not(:disabled):not(.disabled):active:focus, .btn-primary-pastel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-pastel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 243, 253, 0.5); }

.btn-secondary-pastel {
  color: #fff;
  background-color: rgba(233, 0, 129, 0.05);
  border-color: rgba(233, 0, 129, 0.05); }
  .btn-secondary-pastel:hover {
    color: #fff;
    background-color: rgba(195, 0, 108, 0.05);
    border-color: rgba(182, 0, 101, 0.05); }
  .btn-secondary-pastel:focus, .btn-secondary-pastel.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 223, 239, 0.5); }
  .btn-secondary-pastel.disabled, .btn-secondary-pastel:disabled {
    color: #fff;
    background-color: rgba(233, 0, 129, 0.05);
    border-color: rgba(233, 0, 129, 0.05); }
  .btn-secondary-pastel:not(:disabled):not(.disabled):active, .btn-secondary-pastel:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-pastel.dropdown-toggle {
    color: #fff;
    background-color: rgba(182, 0, 101, 0.05);
    border-color: rgba(169, 0, 94, 0.05); }
    .btn-secondary-pastel:not(:disabled):not(.disabled):active:focus, .btn-secondary-pastel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary-pastel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 223, 239, 0.5); }

.btn-primary-solidarissimo {
  color: #fff;
  background-color: #274c79;
  border-color: #274c79; }
  .btn-primary-solidarissimo:hover {
    color: #fff;
    background-color: #1e3a5c;
    border-color: #1b3452; }
  .btn-primary-solidarissimo:focus, .btn-primary-solidarissimo.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 103, 141, 0.5); }
  .btn-primary-solidarissimo.disabled, .btn-primary-solidarissimo:disabled {
    color: #fff;
    background-color: #274c79;
    border-color: #274c79; }
  .btn-primary-solidarissimo:not(:disabled):not(.disabled):active, .btn-primary-solidarissimo:not(:disabled):not(.disabled).active,
  .show > .btn-primary-solidarissimo.dropdown-toggle {
    color: #fff;
    background-color: #1b3452;
    border-color: #172e49; }
    .btn-primary-solidarissimo:not(:disabled):not(.disabled):active:focus, .btn-primary-solidarissimo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-solidarissimo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 103, 141, 0.5); }

.btn-secondary-solidarissimo {
  color: #fff;
  background-color: #e90081;
  border-color: #e90081; }
  .btn-secondary-solidarissimo:hover {
    color: #fff;
    background-color: #c3006c;
    border-color: #b60065; }
  .btn-secondary-solidarissimo:focus, .btn-secondary-solidarissimo.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 38, 148, 0.5); }
  .btn-secondary-solidarissimo.disabled, .btn-secondary-solidarissimo:disabled {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
  .btn-secondary-solidarissimo:not(:disabled):not(.disabled):active, .btn-secondary-solidarissimo:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-solidarissimo.dropdown-toggle {
    color: #fff;
    background-color: #b60065;
    border-color: #a9005e; }
    .btn-secondary-solidarissimo:not(:disabled):not(.disabled):active:focus, .btn-secondary-solidarissimo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary-solidarissimo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 38, 148, 0.5); }

.btn-outline-primary {
  color: #00a4ed;
  border-color: #00a4ed; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #00a4ed;
    border-color: #00a4ed; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #00a4ed;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00a4ed;
    border-color: #00a4ed; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }

.btn-outline-secondary {
  color: #e90081;
  border-color: #e90081; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #e90081;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #1b1b1b;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1b1b1b;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f2f2f2;
  border-color: #f2f2f2; }
  .btn-outline-light:hover {
    color: #1b1b1b;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f2f2f2;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1b1b1b;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }

.btn-outline-dark {
  color: #3c3c3c;
  border-color: #3c3c3c; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #3c3c3c; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3c3c3c;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #3c3c3c; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5); }

.btn-outline-primary-pastel {
  color: rgba(0, 164, 237, 0.05);
  border-color: rgba(0, 164, 237, 0.05); }
  .btn-outline-primary-pastel:hover {
    color: #fff;
    background-color: rgba(0, 164, 237, 0.05);
    border-color: rgba(0, 164, 237, 0.05); }
  .btn-outline-primary-pastel:focus, .btn-outline-primary-pastel.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }
  .btn-outline-primary-pastel.disabled, .btn-outline-primary-pastel:disabled {
    color: rgba(0, 164, 237, 0.05);
    background-color: transparent; }
  .btn-outline-primary-pastel:not(:disabled):not(.disabled):active, .btn-outline-primary-pastel:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-pastel.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 164, 237, 0.05);
    border-color: rgba(0, 164, 237, 0.05); }
    .btn-outline-primary-pastel:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-pastel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-pastel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }

.btn-outline-secondary-pastel {
  color: rgba(233, 0, 129, 0.05);
  border-color: rgba(233, 0, 129, 0.05); }
  .btn-outline-secondary-pastel:hover {
    color: #fff;
    background-color: rgba(233, 0, 129, 0.05);
    border-color: rgba(233, 0, 129, 0.05); }
  .btn-outline-secondary-pastel:focus, .btn-outline-secondary-pastel.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }
  .btn-outline-secondary-pastel.disabled, .btn-outline-secondary-pastel:disabled {
    color: rgba(233, 0, 129, 0.05);
    background-color: transparent; }
  .btn-outline-secondary-pastel:not(:disabled):not(.disabled):active, .btn-outline-secondary-pastel:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-pastel.dropdown-toggle {
    color: #fff;
    background-color: rgba(233, 0, 129, 0.05);
    border-color: rgba(233, 0, 129, 0.05); }
    .btn-outline-secondary-pastel:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-pastel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-pastel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.btn-outline-primary-solidarissimo {
  color: #274c79;
  border-color: #274c79; }
  .btn-outline-primary-solidarissimo:hover {
    color: #fff;
    background-color: #274c79;
    border-color: #274c79; }
  .btn-outline-primary-solidarissimo:focus, .btn-outline-primary-solidarissimo.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 76, 121, 0.5); }
  .btn-outline-primary-solidarissimo.disabled, .btn-outline-primary-solidarissimo:disabled {
    color: #274c79;
    background-color: transparent; }
  .btn-outline-primary-solidarissimo:not(:disabled):not(.disabled):active, .btn-outline-primary-solidarissimo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-solidarissimo.dropdown-toggle {
    color: #fff;
    background-color: #274c79;
    border-color: #274c79; }
    .btn-outline-primary-solidarissimo:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-solidarissimo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-solidarissimo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 76, 121, 0.5); }

.btn-outline-secondary-solidarissimo {
  color: #e90081;
  border-color: #e90081; }
  .btn-outline-secondary-solidarissimo:hover {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
  .btn-outline-secondary-solidarissimo:focus, .btn-outline-secondary-solidarissimo.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }
  .btn-outline-secondary-solidarissimo.disabled, .btn-outline-secondary-solidarissimo:disabled {
    color: #e90081;
    background-color: transparent; }
  .btn-outline-secondary-solidarissimo:not(:disabled):not(.disabled):active, .btn-outline-secondary-solidarissimo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-solidarissimo.dropdown-toggle {
    color: #fff;
    background-color: #e90081;
    border-color: #e90081; }
    .btn-outline-secondary-solidarissimo:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-solidarissimo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-solidarissimo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00a4ed;
  text-decoration: none; }
  .btn-link:hover {
    color: #006fa1;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #5e5e5e;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8125rem 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 11rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #3c3c3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e6e6e6; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.3rem 0.9375rem;
  clear: both;
  font-weight: 400;
  color: #1b1b1b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:first-child {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .dropdown-item:last-child {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #0e0e0e;
    text-decoration: none;
    background-color: #f2f2f2; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00a4ed; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #5e5e5e;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 0.9375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #5e5e5e;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.3rem 0.9375rem;
  color: #1b1b1b; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #474747;
  text-align: center;
  white-space: nowrap;
  background-color: #e6e6e6;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.4rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00a4ed;
    background-color: #00a4ed; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6ed2ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #a1e2ff;
    border-color: #a1e2ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #5e5e5e; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e6e6e6; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #777 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00a4ed;
  background-color: #00a4ed; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 164, 237, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 164, 237, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 164, 237, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.2rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #777;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 164, 237, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #474747;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23303030' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #6ed2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
    .custom-select:focus::-ms-value {
      color: #474747;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #5e5e5e;
    background-color: #e6e6e6; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.4em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6ed2ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e6e6e6; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: "Parcourir"; }
  .custom-file-input:lang(es) ~ .custom-file-label::after {
    content: "Elegir"; }
  .custom-file-input:lang(de) ~ .custom-file-label::after {
    content: "Reise"; }
  .custom-file-input:lang(it) ~ .custom-file-label::after {
    content: "Percorrere"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.4em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.4;
  color: #474747;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.4em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.4;
    color: #474747;
    content: "Browse";
    background-color: #e6e6e6;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #00a4ed;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #a1e2ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ccc;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #00a4ed;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #a1e2ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ccc;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #00a4ed;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #a1e2ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #ccc;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #ccc;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #777; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #777; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #777; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #5e5e5e;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ccc; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e6e6e6 #e6e6e6 #ccc; }
    .nav-tabs .nav-link.disabled {
      color: #5e5e5e;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #474747;
    background-color: #fff;
    border-color: #ccc #ccc #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00a4ed; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0 - 1px) calc(0 - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0 - 1px) calc(0 - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.3rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.3rem;
    color: #b3b3b3;
    content: "›"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #5e5e5e; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00a4ed;
  background-color: #fff;
  border: 1px solid #ccc; }
  .page-link:hover {
    z-index: 2;
    color: #006fa1;
    text-decoration: none;
    background-color: #e6e6e6;
    border-color: #ccc; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00a4ed;
  border-color: #00a4ed; }

.page-item.disabled .page-link {
  color: #5e5e5e;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ccc; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0; }

.badge-primary {
  color: #fff;
  background-color: #00a4ed; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0081ba; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #e90081; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #b60065; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #1b1b1b;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #1b1b1b;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #1b1b1b;
  background-color: #f2f2f2; }
  a.badge-light:hover, a.badge-light:focus {
    color: #1b1b1b;
    background-color: #d9d8d8; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #3c3c3c; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #232222; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(60, 60, 60, 0.5); }

.badge-primary-pastel {
  color: #fff;
  background-color: rgba(0, 164, 237, 0.05); }
  a.badge-primary-pastel:hover, a.badge-primary-pastel:focus {
    color: #fff;
    background-color: rgba(0, 129, 186, 0.05); }
  a.badge-primary-pastel:focus, a.badge-primary-pastel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 164, 237, 0.5); }

.badge-secondary-pastel {
  color: #fff;
  background-color: rgba(233, 0, 129, 0.05); }
  a.badge-secondary-pastel:hover, a.badge-secondary-pastel:focus {
    color: #fff;
    background-color: rgba(182, 0, 101, 0.05); }
  a.badge-secondary-pastel:focus, a.badge-secondary-pastel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.badge-primary-solidarissimo {
  color: #fff;
  background-color: #274c79; }
  a.badge-primary-solidarissimo:hover, a.badge-primary-solidarissimo:focus {
    color: #fff;
    background-color: #1b3452; }
  a.badge-primary-solidarissimo:focus, a.badge-primary-solidarissimo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 76, 121, 0.5); }

.badge-secondary-solidarissimo {
  color: #fff;
  background-color: #e90081; }
  a.badge-secondary-solidarissimo:hover, a.badge-secondary-solidarissimo:focus {
    color: #fff;
    background-color: #b60065; }
  a.badge-secondary-solidarissimo:focus, a.badge-secondary-solidarissimo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 0, 129, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e6e6e6;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #00557b;
  background-color: #ccedfb;
  border-color: #b8e6fa; }
  .alert-primary hr {
    border-top-color: #a0def8; }
  .alert-primary .alert-link {
    color: #003248; }

.alert-secondary {
  color: #790043;
  background-color: #fbcce6;
  border-color: #f9b8dc; }
  .alert-secondary hr {
    border-top-color: #f7a0d0; }
  .alert-secondary .alert-link {
    color: #460027; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #7e7e7e;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #656464; }

.alert-dark {
  color: #1f1f1f;
  background-color: #d8d8d8;
  border-color: #c8c8c8; }
  .alert-dark hr {
    border-top-color: #bbbbbb; }
  .alert-dark .alert-link {
    color: #060505; }

.alert-primary-pastel {
  color: rgba(0, 4, 6, 0.506);
  background-color: rgba(253, 254, 255, 0.81);
  border-color: rgba(252, 254, 255, 0.734); }
  .alert-primary-pastel hr {
    border-top-color: rgba(227, 246, 255, 0.734); }
  .alert-primary-pastel .alert-link {
    color: rgba(0, 0, 0, 0.506); }

.alert-secondary-pastel {
  color: rgba(6, 0, 3, 0.506);
  background-color: rgba(255, 253, 254, 0.81);
  border-color: rgba(255, 252, 254, 0.734); }
  .alert-secondary-pastel hr {
    border-top-color: rgba(255, 227, 246, 0.734); }
  .alert-secondary-pastel .alert-link {
    color: rgba(0, 0, 0, 0.506); }

.alert-primary-solidarissimo {
  color: #14283f;
  background-color: #d4dbe4;
  border-color: #c3cdd9; }
  .alert-primary-solidarissimo hr {
    border-top-color: #b3c0cf; }
  .alert-primary-solidarissimo .alert-link {
    color: #080f18; }

.alert-secondary-solidarissimo {
  color: #790043;
  background-color: #fbcce6;
  border-color: #f9b8dc; }
  .alert-secondary-solidarissimo hr {
    border-top-color: #f7a0d0; }
  .alert-secondary-solidarissimo .alert-link {
    color: #460027; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e6e6e6;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00a4ed;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #474747;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #474747;
    text-decoration: none;
    background-color: #f2f2f2; }
  .list-group-item-action:active {
    color: #3c3c3c;
    background-color: #e6e6e6; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #5e5e5e;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #00a4ed;
    border-color: #00a4ed; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #00557b;
  background-color: #b8e6fa; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00557b;
    background-color: #a0def8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00557b;
    border-color: #00557b; }

.list-group-item-secondary {
  color: #790043;
  background-color: #f9b8dc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #790043;
    background-color: #f7a0d0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #790043;
    border-color: #790043; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #7e7e7e;
  background-color: #fbfbfb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7e7e7e;
    background-color: #eeeeee; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7e7e7e;
    border-color: #7e7e7e; }

.list-group-item-dark {
  color: #1f1f1f;
  background-color: #c8c8c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f1f1f;
    background-color: #bbbbbb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f; }

.list-group-item-primary-pastel {
  color: rgba(0, 4, 6, 0.506);
  background-color: rgba(252, 254, 255, 0.734); }
  .list-group-item-primary-pastel.list-group-item-action:hover, .list-group-item-primary-pastel.list-group-item-action:focus {
    color: rgba(0, 4, 6, 0.506);
    background-color: rgba(227, 246, 255, 0.734); }
  .list-group-item-primary-pastel.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 4, 6, 0.506);
    border-color: rgba(0, 4, 6, 0.506); }

.list-group-item-secondary-pastel {
  color: rgba(6, 0, 3, 0.506);
  background-color: rgba(255, 252, 254, 0.734); }
  .list-group-item-secondary-pastel.list-group-item-action:hover, .list-group-item-secondary-pastel.list-group-item-action:focus {
    color: rgba(6, 0, 3, 0.506);
    background-color: rgba(255, 227, 246, 0.734); }
  .list-group-item-secondary-pastel.list-group-item-action.active {
    color: #fff;
    background-color: rgba(6, 0, 3, 0.506);
    border-color: rgba(6, 0, 3, 0.506); }

.list-group-item-primary-solidarissimo {
  color: #14283f;
  background-color: #c3cdd9; }
  .list-group-item-primary-solidarissimo.list-group-item-action:hover, .list-group-item-primary-solidarissimo.list-group-item-action:focus {
    color: #14283f;
    background-color: #b3c0cf; }
  .list-group-item-primary-solidarissimo.list-group-item-action.active {
    color: #fff;
    background-color: #14283f;
    border-color: #14283f; }

.list-group-item-secondary-solidarissimo {
  color: #790043;
  background-color: #f9b8dc; }
  .list-group-item-secondary-solidarissimo.list-group-item-action:hover, .list-group-item-secondary-solidarissimo.list-group-item-action:focus {
    color: #790043;
    background-color: #f7a0d0; }
  .list-group-item-secondary-solidarissimo.list-group-item-action.active {
    color: #fff;
    background-color: #790043;
    border-color: #790043; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #5e5e5e;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.4; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #ccc;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #3c3c3c;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #3c3c3c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #00a4ed !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0081ba !important; }

.bg-secondary {
  background-color: #e90081 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #b60065 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f2f2f2 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d9d8d8 !important; }

.bg-dark {
  background-color: #3c3c3c !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #232222 !important; }

.bg-primary-pastel {
  background-color: rgba(0, 164, 237, 0.05) !important; }

a.bg-primary-pastel:hover, a.bg-primary-pastel:focus,
button.bg-primary-pastel:hover,
button.bg-primary-pastel:focus {
  background-color: rgba(0, 129, 186, 0.05) !important; }

.bg-secondary-pastel {
  background-color: rgba(233, 0, 129, 0.05) !important; }

a.bg-secondary-pastel:hover, a.bg-secondary-pastel:focus,
button.bg-secondary-pastel:hover,
button.bg-secondary-pastel:focus {
  background-color: rgba(182, 0, 101, 0.05) !important; }

.bg-primary-solidarissimo {
  background-color: #274c79 !important; }

a.bg-primary-solidarissimo:hover, a.bg-primary-solidarissimo:focus,
button.bg-primary-solidarissimo:hover,
button.bg-primary-solidarissimo:focus {
  background-color: #1b3452 !important; }

.bg-secondary-solidarissimo {
  background-color: #e90081 !important; }

a.bg-secondary-solidarissimo:hover, a.bg-secondary-solidarissimo:focus,
button.bg-secondary-solidarissimo:hover,
button.bg-secondary-solidarissimo:focus {
  background-color: #b60065 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ccc !important; }

.border-top {
  border-top: 1px solid #ccc !important; }

.border-right {
  border-right: 1px solid #ccc !important; }

.border-bottom {
  border-bottom: 1px solid #ccc !important; }

.border-left {
  border-left: 1px solid #ccc !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #00a4ed !important; }

.border-secondary {
  border-color: #e90081 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f2f2f2 !important; }

.border-dark {
  border-color: #3c3c3c !important; }

.border-primary-pastel {
  border-color: rgba(0, 164, 237, 0.05) !important; }

.border-secondary-pastel {
  border-color: rgba(233, 0, 129, 0.05) !important; }

.border-primary-solidarissimo {
  border-color: #274c79 !important; }

.border-secondary-solidarissimo {
  border-color: #e90081 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #00a4ed !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #006fa1 !important; }

.text-secondary {
  color: #e90081 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #9d0057 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f2f2f2 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cccccc !important; }

.text-dark {
  color: #3c3c3c !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #161616 !important; }

.text-primary-pastel {
  color: rgba(0, 164, 237, 0.05) !important; }

a.text-primary-pastel:hover, a.text-primary-pastel:focus {
  color: rgba(0, 111, 161, 0.05) !important; }

.text-secondary-pastel {
  color: rgba(233, 0, 129, 0.05) !important; }

a.text-secondary-pastel:hover, a.text-secondary-pastel:focus {
  color: rgba(157, 0, 87, 0.05) !important; }

.text-primary-solidarissimo {
  color: #274c79 !important; }

a.text-primary-solidarissimo:hover, a.text-primary-solidarissimo:focus {
  color: #14283f !important; }

.text-secondary-solidarissimo {
  color: #e90081 !important; }

a.text-secondary-solidarissimo:hover, a.text-secondary-solidarissimo:focus {
  color: #9d0057 !important; }

.text-body {
  color: #3c3c3c !important; }

.text-muted {
  color: #b3b3b3 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #777;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ccc !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ccc; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc; } }

.-fullwidth {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

.-fullwidth-right {
  width: 100vw;
  position: relative;
  left: 0;
  right: 50%;
  margin-right: -50vw; }
  @media (max-width: 767.98px) {
    .-fullwidth-right {
      width: auto;
      position: unset;
      margin-right: 0; } }

.-title-span-faded {
  text-align: center;
  position: relative; }
  @media (min-width: 768px) {
    .-title-span-faded {
      font-size: 3.9rem !important; } }
  .-title-span-faded span {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: .05;
    font-size: 1.5625rem; }
    @media (min-width: 768px) {
      .-title-span-faded span {
        font-size: 4.375rem; } }
    @media (min-width: 992px) {
      .-title-span-faded span {
        font-size: 6.25rem; } }

@media (min-width: 768px) {
  .-title-letter-spaced, h1, .h1 {
    letter-spacing: 3px; } }

.text-transform-none {
  text-transform: none !important; }

.-shadow {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05); }
  .-shadow:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); }

@media (max-width: 991.98px) {
  .-col-before-main main {
    order: 2; }
  .-col-before-main #aside {
    order: 1;
    margin-top: 0 !important;
    margin-bottom: 1.5rem; } }

.-menu-in-col .nav {
  display: block; }
  .-menu-in-col .nav li a {
    color: unset;
    display: block;
    padding: 0.3125rem 0;
    transition: all 0.2s ease-in-out; }
    .-menu-in-col .nav li a:hover {
      padding-left: 0.3125rem;
      text-decoration: none; }

#b4-grid-overlay {
  display: none; }

#breakpoint-debug {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #dc3545;
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding: 1rem; }

@keyframes zoom-in {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.1); } }

.btn {
  white-space: normal;
  /*overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;*/
  text-transform: uppercase;
  border-radius: 3rem;
  font-family: "Oswald", sans-serif;
  font-weight: 500; }

blockquote {
  padding: 1rem 0 1rem 2.5rem;
  position: relative;
  /*&:after {
    content: "\201D";
  }*/ }
  blockquote:before {
    content: "\201C";
    position: absolute;
    left: 0;
    top: 0.6875rem;
    font-size: 5.625rem;
    color: #00a4ed;
    line-height: 1; }

.accordion > .card {
  margin-bottom: 0; }
  .accordion > .card:not(:first-child) {
    margin-top: -2px; }
  .accordion > .card:last-child > .card-header {
    border-bottom: none; }
    .accordion > .card:last-child > .card-header > * > *:not(.collapsed) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion > .card > .card-header {
    padding: 0; }
    .accordion > .card > .card-header > * {
      margin-bottom: 0; }
      .accordion > .card > .card-header > * > * {
        width: 100%;
        cursor: pointer;
        display: block;
        padding: 0.625rem 2.5rem 0.625rem 0.625rem;
        text-decoration: none;
        position: relative;
        text-align: left;
        text-transform: none;
        color: #3c3c3c;
        font-size: 1rem !important; }
        .accordion > .card > .card-header > * > *:after {
          content: "−";
          position: absolute;
          top: 50%;
          right: 0.9375rem;
          transform: translateY(-50%);
          line-height: 0;
          font-size: 1.375rem;
          font-weight: 400;
          font-family: sans-serif; }
        .accordion > .card > .card-header > * > *.collapsed:after {
          content: "+"; }

.accordion.-accordion-decli-1 > .card {
  margin-bottom: 0.5rem; }
  .accordion.-accordion-decli-1 > .card:last-child > .card-header > * > *:not(.collapsed) {
    border-bottom: none; }
  .accordion.-accordion-decli-1 > .card > .card-header {
    transition: all 0.2s ease-in-out;
    border-bottom: 0;
    background-color: #fff; }
    .accordion.-accordion-decli-1 > .card > .card-header:hover {
      background-color: #f2f2f2; }
    .accordion.-accordion-decli-1 > .card > .card-header > * > * {
      padding: 0.9375rem 2.5rem 0.9375rem 1.25rem;
      font-size: 1.2rem !important;
      color: #00a4ed !important; }
      .accordion.-accordion-decli-1 > .card > .card-header > * > *:after {
        display: none !important; }
      .accordion.-accordion-decli-1 > .card > .card-header > * > * .fa-angle-down, .accordion.-accordion-decli-1 > .card > .card-header > * > * .fa-angle-right {
        top: 50%;
        right: 0.9375rem;
        transform: translateY(-50%); }
      .accordion.-accordion-decli-1 > .card > .card-header > * > * .fa-angle-down {
        display: block; }
      .accordion.-accordion-decli-1 > .card > .card-header > * > * .fa-angle-right {
        display: none; }
      .accordion.-accordion-decli-1 > .card > .card-header > * > *.collapsed .fa-angle-down {
        display: none; }
      .accordion.-accordion-decli-1 > .card > .card-header > * > *.collapsed .fa-angle-right {
        display: block; }
  .accordion.-accordion-decli-1 > .card > .card-body {
    padding-top: 0;
    padding-bottom: 0; }
    .accordion.-accordion-decli-1 > .card > .card-body li a:hover {
      text-decoration: none; }
      .accordion.-accordion-decli-1 > .card > .card-body li a:hover > span:first-child {
        left: 1rem;
        color: #00a4ed; }
      .accordion.-accordion-decli-1 > .card > .card-body li a:hover > span:last-child {
        left: 1rem; }
        @media (min-width: 768px) {
          .accordion.-accordion-decli-1 > .card > .card-body li a:hover > span:last-child {
            left: inherit;
            right: 1rem; } }
    .accordion.-accordion-decli-1 > .card > .card-body li a > span:first-child {
      transition: all 0.2s ease-in-out;
      left: 0;
      font-size: 1.125rem; }
    .accordion.-accordion-decli-1 > .card > .card-body li a > span:last-child {
      left: 0; }
      @media (min-width: 768px) {
        .accordion.-accordion-decli-1 > .card > .card-body li a > span:last-child {
          left: inherit;
          right: 0; } }

@media (min-width: 768px) {
  .accordion-sm.card {
    border: none;
    border-radius: 0; }
    .accordion-sm.card:not(:first-child) {
      margin-top: 0; }
  .accordion-sm > .collapse, .accordion-sm > .collapsing {
    display: block !important;
    height: auto !important; }
    .accordion-sm > .collapse > .card-body, .accordion-sm > .collapsing > .card-body {
      padding: 0; }
  .accordion-sm > .card-header {
    background-color: transparent;
    border-radius: 0;
    border: none; }
    .accordion-sm > .card-header > * > * {
      padding: 0 !important;
      cursor: default; }
      .accordion-sm > .card-header > * > *:after {
        display: none; }
      .accordion-sm > .card-header > * > *:not(.collapsed) {
        border-bottom: none !important; } }

@media (min-width: 992px) {
  .accordion-md.card {
    border: none;
    border-radius: 0; }
    .accordion-md.card:not(:first-child) {
      margin-top: 0; }
  .accordion-md > .collapse, .accordion-md > .collapsing {
    display: block !important;
    height: auto !important; }
    .accordion-md > .collapse > .card-body, .accordion-md > .collapsing > .card-body {
      padding: 0; }
  .accordion-md > .card-header {
    background-color: transparent;
    border-radius: 0;
    border: none; }
    .accordion-md > .card-header > * > * {
      padding: 0 !important;
      cursor: default; }
      .accordion-md > .card-header > * > *:after {
        display: none; }
      .accordion-md > .card-header > * > *:not(.collapsed) {
        border-bottom: none !important; } }

.rounded {
  border-radius: 0.3rem !important; }

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important; }

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.fa-xs {
  vertical-align: -.05em; }

/*body.-browser_firefox {

}*/
/*body.-browser_chrome {

}*/
/*
body.-browser_edge {

}
*/
body.-browser_ie #intro-wrapper path {
  fill-opacity: 1 !important;
  stroke: none !important; }

body.-browser_ie #editions-wrapper {
  height: 26.25rem; }

body.-browser_ie .editions.-details #edition-highlights {
  height: 21.875rem; }

body.-browser_ie .-visual-header {
  height: 19.375rem; }

html {
  /*@include media-breakpoint-down(md) {
    // Je réduit la taille des textes et composants que sur la résolution md et inférieurs
    font-size: $font-size-base*.75;
  }*/ }
  @media (max-width: 991.98px) {
    html h1, html .h1 {
      font-size: 2.7846rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    html {
      font-size: 0.875rem; } }

#content-wrapper {
  position: relative;
  z-index: 0; }

.lead {
  color: #00a4ed; }

sup {
  text-transform: lowercase; }

#header-wrapper {
  background-color: #000000;
  z-index: 1030;
  box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.2); }
  @media (min-width: 992px) {
    #header-wrapper {
      box-shadow: none; } }

.-home #header-wrapper {
  position: fixed !important; }

#header .logo img {
  max-width: 125px;
  color: #fff; }

#header #logo img {
  max-height: 2.1875rem; }
  @media (min-width: 768px) {
    #header #logo img {
      max-height: 3.125rem; } }

#main-menu-wrapper {
  background-color: #000000;
  position: relative;
  z-index: 2;
  font-size: 0.875rem; }
  @media (min-width: 1200px) and (max-width: 1320px) {
    #main-menu-wrapper {
      font-size: 0.75rem; } }

#main-menu a, #main-menu span, #secondary-menu a, #secondary-menu span {
  display: block;
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer; }

#main-menu > ul > li.-parent > a, #main-menu > ul > li.-parent > span, #secondary-menu > ul > li.-parent > a, #secondary-menu > ul > li.-parent > span {
  position: relative;
  overflow: hidden;
  padding-right: 1.125rem; }
  #main-menu > ul > li.-parent > a > svg.fa-angle-down, #main-menu > ul > li.-parent > span > svg.fa-angle-down, #secondary-menu > ul > li.-parent > a > svg.fa-angle-down, #secondary-menu > ul > li.-parent > span > svg.fa-angle-down {
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

#main-menu > ul > li ul, #secondary-menu > ul > li ul {
  z-index: 5;
  min-width: 15.625rem;
  background-color: #00a4ed; }
  #main-menu > ul > li ul li, #secondary-menu > ul > li ul li {
    border-bottom: #0092d4 0.0625rem solid; }
    #main-menu > ul > li ul li:last-child, #secondary-menu > ul > li ul li:last-child {
      border-bottom: none; }
    #main-menu > ul > li ul li.-parent.sfHover > a, #main-menu > ul > li ul li.-parent.sfHover > span, #secondary-menu > ul > li ul li.-parent.sfHover > a, #secondary-menu > ul > li ul li.-parent.sfHover > span {
      background-color: #0081ba; }
    #main-menu > ul > li ul li.-parent > a, #main-menu > ul > li ul li.-parent > span, #secondary-menu > ul > li ul li.-parent > a, #secondary-menu > ul > li ul li.-parent > span {
      padding-right: 1.875rem; }
    #main-menu > ul > li ul li.-active > a, #secondary-menu > ul > li ul li.-active > a {
      color: #004c6e; }
  #main-menu > ul > li ul a, #main-menu > ul > li ul span, #secondary-menu > ul > li ul a, #secondary-menu > ul > li ul span {
    padding: 0.625rem 0.9375rem; }
    #main-menu > ul > li ul a.sf-with-ul:after, #main-menu > ul > li ul a.separator:after, #main-menu > ul > li ul span.sf-with-ul:after, #main-menu > ul > li ul span.separator:after, #secondary-menu > ul > li ul a.sf-with-ul:after, #secondary-menu > ul > li ul a.separator:after, #secondary-menu > ul > li ul span.sf-with-ul:after, #secondary-menu > ul > li ul span.separator:after {
      transition: all 0.2s ease-in-out;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.9375rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.1875rem 0 0.1875rem 0.3125rem;
      border-color: transparent transparent transparent #fff; }
    #main-menu > ul > li ul a.sf-with-ul:hover:after, #main-menu > ul > li ul a.separator:hover:after, #main-menu > ul > li ul span.sf-with-ul:hover:after, #main-menu > ul > li ul span.separator:hover:after, #secondary-menu > ul > li ul a.sf-with-ul:hover:after, #secondary-menu > ul > li ul a.separator:hover:after, #secondary-menu > ul > li ul span.sf-with-ul:hover:after, #secondary-menu > ul > li ul span.separator:hover:after {
      right: 0.625rem; }
    #main-menu > ul > li ul a:hover, #main-menu > ul > li ul span:hover, #secondary-menu > ul > li ul a:hover, #secondary-menu > ul > li ul span:hover {
      background-color: #0081ba; }

#main-menu {
  position: relative;
  padding: 0 1rem;
  /*.item-171 {
    position: static !important;
    .-level-1 {
      //Décommenter pour intégrer/debug de l'affichage des menus fullwidth
      //display: block !important;
      //
      column-count: 3;
      column-gap: px-to-rem(40);
      column-rule: 1px solid rgba(color("white"), .2);
      left: $grid-gutter-width/2;
      right: $grid-gutter-width/2;
      position: absolute;
      z-index: 1;
      padding: spacers(4);
      .sf-with-ul {
        > span {
          padding-right: 0;
          &:after {
            display: none;
          }
        }
      }
      li {
        border-bottom: none;
      }
      > li {
        break-inside: avoid;
        page-break-inside: avoid; // Pour Firefox
        padding: 0;
        > a {
          font-weight: $font-weight-bold;
          padding: 0;
          margin-bottom: px-to-rem(5);
        }
        > span {
          font-weight: $font-weight-bold;
          padding: 0;
          background: none !important;
          margin-bottom: px-to-rem(5);
          &:hover {
            background: none;
          }
          &:after {
            display: none;
          }
        }
        a {
          border-top: none;
          &:hover {
            background: none;
          }
        }
      }
    }
    .-level-2 {
      display: block !important;
      position: static;
      width: auto;
      background: transparent;
      opacity: 1 !important;
      box-shadow: none;
      padding-bottom: spacers(3);
      > li {
        padding: 0;
        margin-bottom: spacers(2);
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
      a {
        position: relative;
        padding: 0 0 0 px-to-rem(12);
        // Styles des puces
        &:before {
          content: "";
          display: block;
          background-color: rgba(color("white"), .5);
          border-radius: 100%;
          height: px-to-rem(5);
          width: px-to-rem(5);
          position: absolute;
          left: 0;
          top: px-to-rem(8);
        }
        //
        &:hover {
          text-decoration: underline;
        }
      }
      ul {
        // Si l'arbo est plus profonde que 3 niveaux je cache les suivants
        display: none !important;
      }
    }
    ul {
      min-width: 0;
    }
  }*/ }
  @media (min-width: 1425px) {
    #main-menu {
      padding: 0 1.5rem; } }
  #main-menu > ul {
    display: flex; }
    #main-menu > ul > li {
      padding: 0 0 0 1rem; }
      @media (min-width: 1550px) {
        #main-menu > ul > li {
          padding: 0 1rem; } }
      #main-menu > ul > li:hover > a > svg.fa-angle-down, #main-menu > ul > li:hover > span > svg.fa-angle-down {
        margin-top: 0.0625rem; }
      #main-menu > ul > li:hover > a:before, #main-menu > ul > li:hover > span:before {
        opacity: 1;
        bottom: 0; }
      #main-menu > ul > li.-active > a, #main-menu > ul > li.-active > span {
        color: #00a4ed; }
      #main-menu > ul > li:first-child {
        padding-left: 0; }
      #main-menu > ul > li:last-child {
        padding-right: 0; }
      #main-menu > ul > li:last-child:after {
        display: none; }
      #main-menu > ul > li > a, #main-menu > ul > li > span {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        white-space: nowrap;
        position: relative;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        text-align: center; }
        #main-menu > ul > li > a:before, #main-menu > ul > li > span:before {
          transition: all 0.2s ease-in-out;
          opacity: 0;
          position: absolute;
          content: "";
          display: block;
          left: 50%;
          bottom: -0.3125rem;
          margin-left: -0.3125rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0.3125rem 0.3125rem 0.3125rem;
          border-color: transparent transparent #00a4ed transparent; }

#secondary-menu a, #secondary-menu span {
  text-align: left; }

#secondary-menu > ul > li {
  padding: 0 1rem 0 0; }
  @media (min-width: 1550px) {
    #secondary-menu > ul > li {
      padding: 0 1rem; } }
  #secondary-menu > ul > li.-parent {
    padding-right: 0; }
    #secondary-menu > ul > li.-parent > a, #secondary-menu > ul > li.-parent > span {
      padding-right: 1.25rem; }
      @media (min-width: 1200px) {
        #secondary-menu > ul > li.-parent > a, #secondary-menu > ul > li.-parent > span {
          padding-right: 1.875rem; } }
      #secondary-menu > ul > li.-parent > a > svg.fa-angle-down, #secondary-menu > ul > li.-parent > span > svg.fa-angle-down {
        right: 0.5rem; }
        @media (min-width: 1200px) {
          #secondary-menu > ul > li.-parent > a > svg.fa-angle-down, #secondary-menu > ul > li.-parent > span > svg.fa-angle-down {
            right: 0.625rem; } }
  @media (min-width: 992px) {
    #secondary-menu > ul > li ul {
      left: inherit;
      right: 0; } }

#secondary-menu .-svg-icone_presse {
  width: 1.875rem; }

#secondary-menu .-svg-icone_exposant {
  height: 1.875rem; }

.btn-ticketing {
  font-size: 0.875rem; }
  @media (min-width: 1200px) and (max-width: 1320px) {
    .btn-ticketing {
      font-size: 0.75rem; } }
  .btn-ticketing span:first-of-type {
    display: inline !important; }
    @media (min-width: 992px) {
      .btn-ticketing span:first-of-type {
        display: none !important; } }
  .btn-ticketing span:last-of-type {
    display: none !important; }
    @media (min-width: 992px) {
      .btn-ticketing span:last-of-type {
        display: inline !important; } }
  .btn-ticketing .-svg-icone_billetterie {
    width: 2rem; }
  .btn-ticketing .fa-external-link {
    display: none; }

#social-menu a, #social-menu span {
  display: block;
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out; }
  #social-menu a svg, #social-menu span svg {
    transition: all 0.2s ease-in-out; }

#social-menu > ul > li {
  padding: 0 .5rem; }
  @media (min-width: 1425px) {
    #social-menu > ul > li {
      padding: 0 1rem; } }

#social-menu .svg-inline--fa.fa-external-link {
  display: none !important; }

header h1 .subtitle {
  padding-top: 1rem;
  margin-top: 1rem;
  position: relative; }
  header h1 .subtitle:before {
    content: "";
    background-color: #00a4ed;
    width: 7.5rem;
    height: 0.1875rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }

#footer {
  font-size: 0.8125rem;
  background-color: #000000;
  color: #fff;
  background-image: url("../img/customer_specific/bkg_footer_mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 768px) {
    #footer {
      background-image: url("../img/customer_specific/bkg_footer.jpg"); } }
  #footer a {
    text-decoration: underline;
    color: #fff; }
    #footer a:hover {
      text-decoration: none; }

#footer-legals {
  font-size: 0.75rem; }
  #footer-legals li:before {
    content: "-";
    margin: 0 0.125rem; }
  @media (max-width: 767.98px) {
    #footer-legals li:first-child:before {
      display: none; } }

#footer-modules #logo-footer img {
  width: 12.125rem; }
  @media (min-width: 768px) {
    #footer-modules #logo-footer img {
      width: 15.5625rem; } }

#footer-modules .-mod-92 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    #footer-modules .-mod-92 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%; } }

#footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.3); }

#footer-creator {
  font-size: 0.75rem;
  margin-right: 0; }
  #footer-creator svg {
    transition: all 0.2s ease-in-out; }
    #footer-creator svg.-svg-logo-ukoo {
      width: 3.5rem;
      height: 1.5625rem;
      margin-right: 0.25rem;
      margin-top: -0.5rem; }
    #footer-creator svg.-svg-logo-publicore {
      width: 3.75rem;
      height: 1.125rem;
      margin-left: 0.25rem;
      margin-top: -0.25rem; }

#bottom-wrapper {
  background-color: #f2f2f2; }

/*
@media print {
  // [ukoo_alex] [2018-02-08 13:29] -> Passer en priorité par les outils/classes BS4 pour gérer affichage print
}
*/
h1, .h1 {
  text-transform: uppercase;
  margin-bottom: 1rem; }

h2, .h2 {
  text-transform: uppercase; }

.-home .title.h2 {
  font-size: 2rem; }
  @media (min-width: 992px) {
    .-home .title.h2 {
      font-size: 4.625rem; } }

.title {
  text-transform: uppercase;
  position: relative;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem; }
  .title.title-decoration.text-center:after {
    content: "";
    background-color: #00a4ed;
    width: 7.5rem;
    height: 0.1875rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .title.title-decoration.text-center.-secondary:after {
    background-color: #e90081; }
  .title.title-decoration.text-center.-primary-solidarissimo:after {
    background-color: #274c79; }
  .title.title-decoration.h6:after {
    width: 3.125rem; }
  @media (min-width: 992px) {
    .title.title-decoration.text-lg-left:after {
      left: 3.75rem; } }

.subtitle {
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: 1rem; }
  @media (min-width: 992px) {
    .subtitle {
      margin-bottom: 2.8125rem; } }
  .subtitle.h3 {
    font-size: 1.625rem; }
    @media (min-width: 992px) {
      .subtitle.h3 {
        font-size: 2rem; } }

.alert-light {
  border-color: #e6e6e6;
  background-color: #e6e6e6; }

a:hover .fa-facebook-square {
  color: #3b5998; }

a:hover .fa-twitter-square {
  color: #00aced; }

a:hover .fa-instagram {
  color: #bc2a8d; }

.-visual-header {
  min-height: 19.375rem; }
  .-visual-header > .position-absolute {
    left: 0;
    top: 0; }
    .-visual-header > .position-absolute:before {
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
    .-visual-header > .position-absolute.-primary:before {
      background-color: rgba(0, 164, 237, 0.7); }
    .-visual-header > .position-absolute.-secondary:before {
      background-color: rgba(233, 0, 129, 0.7); }
    .-visual-header > .position-absolute + div {
      z-index: 2; }
  .-visual-header img {
    object-fit: cover; }
  .-visual-header .date {
    font-size: 1.5rem; }
    .-visual-header .date a {
      font-size: 1rem; }
  .-visual-header .ticketing {
    font-size: 1.25rem; }

.btn {
  border-radius: 0;
  text-transform: unset;
  font-family: "Montserrat", sans-serif;
  border-width: 0.125rem; }

.btn-ticketing {
  color: #00a4ed !important; }
  .btn-ticketing:hover {
    color: #fff !important; }

@media (min-width: 1200px) {
  #top {
    padding: 0 6.25rem; } }

.bg-image {
  object-fit: cover;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0; }

.-overlay-primary:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 164, 237, 0.7); }

.masonry-grid div > a:hover .overlay {
  opacity: 1; }

.masonry-grid .masonry-item {
  margin-bottom: 1rem; }

.masonry-grid .masonry-sizer,
.masonry-grid .masonry-item {
  width: 100%; }
  @media (min-width: 768px) {
    .masonry-grid .masonry-sizer,
    .masonry-grid .masonry-item {
      width: calc((100% - 15px) / 2); } }
  @media (min-width: 992px) {
    .masonry-grid .masonry-sizer,
    .masonry-grid .masonry-item {
      width: calc((100% - 30px) / 3); } }

.masonry-grid .overlay {
  transition: all .2s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 31, 32, 0.7);
  z-index: 1; }

#ukoo-cookies-accept {
  background-color: #00a4ed !important; }
  #ukoo-cookies-accept:hover {
    background-color: #0092d4 !important; }

/************ Page de connexion *******************/
.login {
  padding: 1.5rem;
  background-color: #f2f2f2; }
  .login input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #3c3c3c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

/****** Décoration de titre ******/
aside .module {
  margin-bottom: 1rem;
  padding: 1.5rem;
  background-color: #f2f2f2; }

.module .module-title {
  text-align: center;
  position: relative; }
  .module .module-title:after {
    content: "";
    background-color: #00a4ed;
    width: 5rem;
    height: 0.1875rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }

.module.bg-secondary-pastel .module-title:after {
  background-color: #e90081; }

/******** Connexion *********/
.lang_de #username-lbl {
  visibility: hidden;
  position: relative; }
  .lang_de #username-lbl:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Kennung"; }

.lang_de #password-lbl {
  visibility: hidden;
  position: relative; }
  .lang_de #password-lbl:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Passwort"; }

.lang_en #username-lbl {
  visibility: hidden;
  position: relative; }
  .lang_en #username-lbl:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Username"; }

.lang_en #password-lbl {
  visibility: hidden;
  position: relative; }
  .lang_en #password-lbl:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Password"; }

.ax-widget p {
  font-size: inherit !important; }

/*@font-face {
  font-family: 'AvantGardeGothicITC';
  src: url('../fonts/AvantGardeGothicITC/33BF15_0_0.eot');
  src: url('../fonts/AvantGardeGothicITC/33BF15_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/AvantGardeGothicITC/33BF15_0_0.woff2') format('woff2'), url('../fonts/AvantGardeGothicITC/33BF15_0_0.woff') format('woff'), url('../fonts/AvantGardeGothicITC/33BF15_0_0.ttf') format('truetype');
  font-weight: $font-weight-normal;
}*/
/*@font-face {
  font-family: 'AvantGardeGothicITC';
  src: url('../fonts/AvantGardeGothicITC/33BF15_1_0.eot');
  src: url('../fonts/AvantGardeGothicITC/33BF15_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/AvantGardeGothicITC/33BF15_1_0.woff2') format('woff2'), url('../fonts/AvantGardeGothicITC/33BF15_1_0.woff') format('woff'), url('../fonts/AvantGardeGothicITC/33BF15_1_0.ttf') format('truetype');
  font-weight: $font-weight-bold;
}*/
/*
@font-face {
  font-family: 'AvantGardeGothicITC';
  src: url('../fonts/AvantGardeGothicITC/33BF15_2_0.eot');
  src: url('../fonts/AvantGardeGothicITC/33BF15_2_0.eot?#iefix') format('embedded-opentype'), url('../fonts/AvantGardeGothicITC/33BF15_2_0.woff2') format('woff2'), url('../fonts/AvantGardeGothicITC/33BF15_2_0.woff') format('woff'), url('../fonts/AvantGardeGothicITC/33BF15_2_0.ttf') format('truetype');
  font-weight: $fontweight-light;
}*/
svg {
  pointer-events: none; }
  svg.svg-icon {
    width: 1.2em;
    height: 1.2em;
    max-width: 100%;
    fill: currentColor; }

.system-message .alert {
  /*&.alert-warning {}*/ }
  .system-message .alert.alert-message {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
    .system-message .alert.alert-message hr {
      border-top-color: #b1dfbb; }
    .system-message .alert.alert-message .alert-link {
      color: #0b2e13; }
  .system-message .alert.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
    .system-message .alert.alert-error hr {
      border-top-color: #f1b0b7; }
    .system-message .alert.alert-error .alert-link {
      color: #491217; }
  .system-message .alert.alert-notice {
    position: relative;
    color: #1f1f1f;
    background-color: #d8d8d8;
    border-color: #c8c8c8; }
    .system-message .alert.alert-notice hr {
      border-top-color: #bbbbbb; }
    .system-message .alert.alert-notice .alert-link {
      color: #060505; }

.system-message .close {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  text-decoration: none; }

img {
  width: auto;
  height: auto;
  max-width: 100%; }

@media screen and (max-width: 320px) {
  [id*="g-recaptcha"] {
    max-width: 280px; }
    [id*="g-recaptcha"] > div {
      transform: scale(0.928);
      transform-origin: 0 0; } }

.fluid-video {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* 16/9 format */
  overflow: hidden;
  position: relative; }
  .fluid-video iframe, .fluid-video embed, .fluid-video object {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 09:59] ->  A commenter si non utilisé */
.events.-details .representations li {
  font-size: 1.125rem; }

.events.-details .representations .btn {
  font-size: 0.875rem; }
  .events.-details .representations .btn .fa-external-link {
    display: none !important; }
  .events.-details .representations .btn:hover {
    background-color: #000000;
    color: #fff !important; }

.-urgpd h1, .-urgpd h2 {
  position: relative;
  padding-bottom: 1rem; }

.-urgpd h1:after {
  content: "";
  background-color: #00a4ed;
  width: 7.5rem;
  height: 0.1875rem;
  position: absolute;
  bottom: 0;
  left: 0; }

.-urgpd h2:after {
  content: "";
  background-color: #00a4ed;
  width: 5rem;
  height: 0.1875rem;
  position: absolute;
  bottom: 0;
  left: 0; }

@media (min-width: 992px) {
  body.-home #top-wrapper .module {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; } }

.-mod-seo {
  font-size: 0.75rem; }
  .-mod-seo h1 {
    font-size: 0.875rem;
    letter-spacing: inherit;
    text-transform: none; }
  .-mod-seo h2, .-mod-seo h3 {
    font-size: 0.75rem;
    text-transform: none; }
  .-mod-seo [data-readmore] {
    padding-bottom: 1.5rem; }
    .-mod-seo [data-readmore]:after {
      background: linear-gradient(rgba(242, 242, 242, 0) 0%, #f2f2f2 99%); }

.-mod-111 {
  background-color: rgba(0, 164, 237, 0.05); }

@media (min-width: 992px) {
  .-mod-articles-category .content > div {
    background-image: url(/templates/ukoobs4/img/customer_specific/bg_logbook.jpg);
    background-size: cover; } }

.-mod-articles-category li .title:after {
  content: "";
  display: block;
  position: absolute;
  width: 3.125rem;
  height: 0.125rem;
  background-color: #00a4ed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: .3s ease-in-out; }

.-mod-articles-category a:hover .title:after {
  width: 5rem; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 10:00] -> A commenter si non utilisé */
.-mod-ukoo-mailing .title {
  font-size: 1.75rem; }
  @media (min-width: 992px) {
    .-mod-ukoo-mailing .title {
      font-size: 2.375rem; } }
  .-mod-ukoo-mailing .title .subtitle {
    color: #e90081;
    font-family: "Crimson Text", serif;
    font-weight: 400;
    font-style: italic;
    font-size: 1.25rem; }

.-mod-ukoo-mailing .error {
  color: #dc3545; }

.-mod-ukoo-mailing .success {
  color: #28a745; }

.-mod-ukoo-mailing .input-group {
  border-color: #fff; }

.-mod-ukoo-mailing .input-group-prepend + input {
  border-radius: 0; }

.-mod-ukoo-mailing .input-group-prepend > .input-group-text {
  border-radius: 0;
  border-color: #fff; }

.-mod-ukoo-mailing input[type="text"], .-mod-ukoo-mailing input[type="email"] {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  background-color: transparent;
  border-color: #fff;
  border-left: none;
  color: #fff; }
  .-mod-ukoo-mailing input[type="text"]::placeholder, .-mod-ukoo-mailing input[type="email"]::placeholder {
    color: #fff; }

.-mod-ukoo-mailing small {
  font-size: 0.625rem; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 10:00] -> A commenter si non utilisé */
#intro-wrapper {
  border-width: 0 0 0.625rem 0;
  border-bottom-style: solid;
  border-image: linear-gradient(to left, #00a4ed, #e90081) 1%; }
  @media (max-width: 991.98px) {
    #intro-wrapper {
      overflow: hidden; } }
  @media (min-width: 992px) {
    #intro-wrapper {
      height: 100vh; } }
  #intro-wrapper:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  #intro-wrapper #intro-background {
    left: 0;
    top: 0;
    overflow: hidden; }
    #intro-wrapper #intro-background img {
      left: 0;
      top: 0;
      object-fit: cover;
      animation: zoom-in 10s ease-out forwards; }
  #intro-wrapper #intro-content {
    z-index: 2;
    position: relative !important; }
    @media (min-width: 992px) {
      #intro-wrapper #intro-content {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); } }
    @media (max-height: 740px) and (min-width: 992px) {
      #intro-wrapper #intro-content .intro-mouse {
        display: none !important; } }
    #intro-wrapper #intro-content .sitv-edition {
      font-family: "Crimson Text", serif;
      font-weight: 400;
      font-style: italic;
      font-size: 4.6875rem;
      line-height: 3.75rem;
      padding-top: 3.125rem; }
      @media (min-width: 992px) {
        #intro-wrapper #intro-content .sitv-edition {
          font-size: 7.5rem;
          line-height: 5rem; } }
      #intro-wrapper #intro-content .sitv-edition sup {
        font-size: 60%; }
    #intro-wrapper #intro-content .h1 {
      font-size: 2rem; }
      @media (min-width: 992px) {
        #intro-wrapper #intro-content .h1 {
          font-size: 3.75rem; } }
    #intro-wrapper #intro-content .subtitle {
      font-family: "Crimson Text", serif;
      font-weight: 400;
      font-style: italic;
      font-size: 1.25rem; }
      @media (min-width: 992px) {
        #intro-wrapper #intro-content .subtitle {
          font-size: 2.5rem; } }
    #intro-wrapper #intro-content .localisation {
      font-family: "Crimson Text", serif;
      font-weight: 400;
      font-style: italic;
      font-size: 0.8125rem; }
      @media (min-width: 992px) {
        #intro-wrapper #intro-content .localisation {
          font-size: 1.25rem; } }

@keyframes colorSlide {
  0% {
    background-position: 0% 100%; }
  20% {
    background-position: 0% 0%; }
  21% {
    background-color: #4e5559; }
  29.99% {
    background-color: #fff;
    background-position: 0% 0%; }
  30% {
    background-color: #4e5559;
    background-position: 0% 100%; }
  50% {
    background-position: 0% 0%; }
  51% {
    background-color: #4e5559; }
  59% {
    background-color: #fff;
    background-position: 0% 0%; }
  60% {
    background-color: #4e5559;
    background-position: 0% 100%; }
  80% {
    background-position: 0% 0%; }
  81% {
    background-color: #4e5559; }
  90%, 100% {
    background-color: #fff; } }

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-8px); }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(2px); }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(16px); }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-8px); }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-8px); }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-8px); }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(2px); }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(16px); }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-8px); }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-8px); }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-8px); }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(2px); }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(16px); }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-8px); }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-8px); } }

@keyframes nudgeMouse {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(8px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(8px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(8px); }
  90% {
    transform: translateY(0); } }

@keyframes nudgeText {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(2px); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(2px); }
  60% {
    transform: translateY(0); }
  80% {
    transform: translateY(2px); }
  90% {
    transform: translateY(0); } }

@keyframes colorText {
  21% {
    color: #4e5559; }
  30% {
    color: #fff; }
  51% {
    color: #4e5559; }
  60% {
    color: #fff; }
  81% {
    color: #4e5559; }
  90% {
    color: #fff; } }
  #intro-wrapper .intro-mouse {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 100%);
    left: 50%; }
    #intro-wrapper .intro-mouse > a {
      position: relative;
      width: 18px;
      height: 34px;
      border-radius: 100px;
      background-size: 225%;
      animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite; }
      #intro-wrapper .intro-mouse > a:before, #intro-wrapper .intro-mouse > a:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
      #intro-wrapper .intro-mouse > a:before {
        width: 14px;
        height: 30px;
        background-color: #222a30;
        border-radius: 100px; }
      #intro-wrapper .intro-mouse > a:after {
        background-color: #fff;
        width: 2px;
        height: 6px;
        animation: trackBallSlide 5s linear infinite; }

.-mod-sitv-focus {
  padding-top: 0 !important; }
  @media (min-width: 992px) {
    .-mod-sitv-focus #focus-wrapper {
      margin-top: 0; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .-mod-sitv-focus #focus-wrapper {
      margin-top: 5.625rem; } }
  @media (min-width: 992px) and (min-width: 1200px) {
    .-mod-sitv-focus #focus-wrapper {
      margin-top: 7.5rem; } }
  @media (min-width: 992px) {
    .-mod-sitv-focus #focus-content {
      background-image: url(/templates/ukoobs4/img/customer_specific/bg_focus.jpg);
      background-size: cover; } }
  @media (min-width: 1200px) {
    .-mod-sitv-focus #focus-content > div > div > p:nth-child(3) {
      width: 75%; } }
  .-mod-sitv-focus .focus-images {
    padding: 0; }
    @media (min-width: 1200px) {
      .-mod-sitv-focus .focus-images {
        padding-right: 1.5rem; } }
    @media (min-width: 1500px) {
      .-mod-sitv-focus .focus-images {
        margin-top: -3.125rem;
        margin-bottom: -3.125rem; } }
    .-mod-sitv-focus .focus-images img {
      object-fit: cover; }

.-mod-sitv-solidarissimo .title > img {
  width: 18.75rem;
  margin-bottom: 0.625rem; }
  @media (min-width: 992px) {
    .-mod-sitv-solidarissimo .title > img {
      width: 31.25rem; } }

@media (min-width: 992px) {
  .-mod-sitv-solidarissimo #solidarissimo-content {
    background-image: url(/templates/ukoobs4/img/customer_specific/bg_solidarissimo.jpg);
    background-size: cover; } }

@media (min-width: 992px) {
  .-mod-sitv-solidarissimo .solidarissimo-illustration {
    margin-top: -5rem;
    margin-bottom: -5rem; } }

@media (min-width: 992px) {
  .-mod-sitv-events .events-access:hover .events-background:after {
    background-color: transparent; } }

.-mod-sitv-events .events-background:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: .5s ease-in-out; }

.-mod-sitv-events .events-background.-overlay-primary:after {
  background-color: rgba(0, 164, 237, 0.7); }

.-mod-sitv-events .events-background.-overlay-secondary:after {
  background-color: rgba(233, 0, 129, 0.7); }

.-mod-sitv-events .animations-content, .-mod-sitv-events .conferences-content {
  padding: 2.8125rem 0; }
  @media (min-width: 992px) {
    .-mod-sitv-events .animations-content, .-mod-sitv-events .conferences-content {
      padding: 10.3125rem 0; } }

@media (min-width: 992px) {
  .-mod-sitv-exhibitors #exhibitors-wrapper {
    background-image: url(/templates/ukoobs4/img/customer_specific/bg_exhibitors.jpg);
    background-size: cover; } }

.-mod-sitv-exhibitors .dropdown-toggle::after {
  display: none; }

.-mod-sitv-exhibitors .dropdown-menu {
  border-color: #e90081;
  border-radius: 0; }
  .-mod-sitv-exhibitors .dropdown-menu .dropdown-item {
    text-align: center;
    color: #e90081; }
    .-mod-sitv-exhibitors .dropdown-menu .dropdown-item:hover {
      background-color: rgba(233, 0, 129, 0.1); }

@media (min-width: 992px) {
  .-mod-sitv-quickaccess #quickaccess-wrapper {
    background-image: url(/templates/ukoobs4/img/customer_specific/bg_quickaccess.jpg);
    background-size: cover; } }

.-mod-sitv-quickaccess svg {
  height: 4.0625rem;
  width: 4.0625rem; }

.-mod-sitv-quickaccess .title-decoration:after {
  transition: .3s ease-in-out; }

.-mod-sitv-quickaccess ul > li > a:hover {
  text-decoration: none !important; }
  .-mod-sitv-quickaccess ul > li > a:hover .title-decoration:after {
    width: 5rem; }

body#error #logo img {
  width: 8.75rem; }

body#offline #logo img {
  width: 8.75rem; }

body#offline #ukoo-cookies-wrapper {
  display: none !important; }

@media (min-width: 768px) {
  .-listing #filterForm {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; } }

.-listing #filterForm .js-stools-field-filter {
  margin-bottom: 0.5rem; }
  @media (min-width: 768px) {
    .-listing #filterForm .js-stools-field-filter {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      flex: 0 0 50%;
      max-width: 50%; } }
  .-listing #filterForm .js-stools-field-filter .dropdown-toggle {
    font-size: 1.125rem; }

.-listing-items li a, .-listing-items div a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  backface-visibility: hidden; }

.-listing-items li article, .-listing-items div article {
  flex: 1 0 auto;
  z-index: 2;
  transition: all 0.2s ease-in-out; }
  .-listing-items li article .badge, .-listing-items div article .badge {
    font-size: 0.85rem;
    padding: 0.625rem 1.25rem;
    font-weight: 400; }
    .-listing-items li article .badge.position-absolute, .-listing-items div article .badge.position-absolute {
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); }

.-listing-items li .title, .-listing-items div .title {
  font-size: 1.375rem;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  line-height: 1; }
  .-listing-items li .title span, .-listing-items div .title span {
    display: block; }

.-listing-items li .date, .-listing-items div .date {
  font-size: 1.125rem; }

.-listing-galleries li figure {
  min-height: 14.375rem;
  overflow: hidden; }
  .-listing-galleries li figure img {
    object-fit: cover; }

.events.-details .event-gallery {
  width: 100%; }
  @media (min-width: 768px) {
    .events.-details .event-gallery {
      width: 50%; } }

.events li.pined .title-decoration:after {
  transition: .3s ease-in-out; }

.events li.pined:hover .title-decoration:after {
  width: 5rem; }

.category .gallery-content {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.-article #main-wrapper ul:not(.masonry-grid-2) li {
  padding-top: 0.5rem; }

.-article .title-decoration:after {
  content: "";
  background-color: #00a4ed;
  width: 7.5rem;
  height: 0.1875rem;
  position: absolute;
  bottom: 0;
  left: 0; }

.-article .title-decoration.-secondary:after {
  background-color: #e90081; }

.-article h2 {
  position: relative;
  padding-bottom: 1rem; }
  .-article h2:after {
    content: "";
    background-color: #00a4ed;
    width: 5rem;
    height: 0.1875rem;
    position: absolute;
    bottom: 0;
    left: 0; }
  .-article h2.-secondary:after {
    background-color: #e90081; }

.-article .text-center h2:after, .-article h2.text-center:after {
  left: 50%;
  transform: translateX(-50%); }

.-article ul.timeline {
  list-style-type: none;
  position: relative; }
  .-article ul.timeline:before {
    content: ' ';
    background: #87daff;
    display: inline-block;
    position: absolute;
    left: 1.8125rem;
    width: 0.125rem;
    height: 100%;
    z-index: 400; }
  .-article ul.timeline > li {
    margin: 1.25rem 0;
    padding-left: 1.25rem; }
    .-article ul.timeline > li:before {
      content: ' ';
      background: white;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 0.1875rem solid #00a4ed;
      left: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
      z-index: 400; }

.sf-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99; }
  .sf-menu ul ul {
    top: 0;
    left: 100%; }

.sf-menu li {
  position: relative; }
  .sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
    display: block; }

.sf-menu > li {
  display: inline-block;
  vertical-align: middle; }

.sf-menu a {
  display: block;
  position: relative; }

/*
	jQuery.mmenu CSS
*/
/*
	jQuery.mmenu oncanvas CSS
*/
.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu,
.mm-menu > .mm-panel {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0; }

.mm-menu {
  box-sizing: border-box;
  background: inherit;
  display: block;
  overflow: hidden;
  padding: 0; }

.mm-panel {
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  transition: transform 0.4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }
  .mm-panel.mm-opened {
    -webkit-transform: translate3d(0%, 0, 0);
    -moz-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0); }
  .mm-panel.mm-subopened {
    -webkit-transform: translate3d(-30%, 0, 0);
    -moz-transform: translate3d(-30%, 0, 0);
    -ms-transform: translate3d(-30%, 0, 0);
    -o-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0); }
  .mm-panel.mm-highest {
    z-index: 1; }

.mm-menu > .mm-panel {
  background: inherit;
  border-color: inherit;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px; }
  .mm-menu > .mm-panel.mm-hasnavbar {
    padding-top: 40px; }
  .mm-menu > .mm-panel:before, .mm-menu > .mm-panel:after {
    content: '';
    display: block;
    height: 20px; }

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important; }

.mm-vertical .mm-listview .mm-panel,
.mm-listview .mm-vertical .mm-panel {
  display: none;
  padding: 10px 0 10px 10px; }
  .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after,
  .mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after {
    border-color: transparent; }

.mm-vertical li.mm-opened > .mm-panel,
li.mm-vertical.mm-opened > .mm-panel {
  display: block; }

.mm-vertical .mm-listview > li > .mm-next,
.mm-listview > li.mm-vertical > .mm-next {
  height: 40px;
  bottom: auto; }
  .mm-vertical .mm-listview > li > .mm-next:after,
  .mm-listview > li.mm-vertical > .mm-next:after {
    top: 16px;
    bottom: auto; }

.mm-vertical .mm-listview > li.mm-opened > .mm-next:after,
.mm-listview > li.mm-vertical.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .mm-navbar > * {
    display: block;
    padding: 10px 0; }
  .mm-navbar a,
  .mm-navbar a:hover {
    text-decoration: none; }
  .mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .mm-navbar .mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    z-index: 1; }
    .mm-navbar .mm-btn:first-child {
      padding-left: 20px;
      left: 0; }
    .mm-navbar .mm-btn:last-child {
      text-align: right;
      padding-right: 20px;
      right: 0; }

.mm-panel .mm-navbar {
  display: none; }

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block; }

.mm-listview,
.mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }

.mm-listview {
  font: inherit;
  font-size: 15px;
  line-height: 20px; }
  .mm-listview a,
  .mm-listview a:hover {
    text-decoration: none; }
  .mm-listview > li {
    position: relative; }
    .mm-listview > li, .mm-listview > li:after,
    .mm-listview > li .mm-next,
    .mm-listview > li .mm-next:before {
      border-color: inherit; }
    .mm-listview > li > a,
    .mm-listview > li > span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: inherit;
      display: block;
      padding: 10px 10px 10px 20px;
      margin: 0; }
      .mm-listview > li > a.mm-arrow,
      .mm-listview > li > span.mm-arrow {
        padding-right: 50px; }
    .mm-listview > li:not(.mm-divider):after {
      content: '';
      border-bottom-width: 1px;
      border-bottom-style: solid;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
    .mm-listview > li:not(.mm-divider):after {
      left: 20px; }
  .mm-listview .mm-next {
    background: rgba(3, 2, 1, 0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2; }
    .mm-listview .mm-next:before {
      content: '';
      border-left-width: 1px;
      border-left-style: solid;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; }
    .mm-listview .mm-next + a,
    .mm-listview .mm-next + span {
      margin-right: 50px; }
    .mm-listview .mm-next.mm-fullsubopen {
      width: 100%; }
      .mm-listview .mm-next.mm-fullsubopen:before {
        border-left: none; }
      .mm-listview .mm-next.mm-fullsubopen + a,
      .mm-listview .mm-next.mm-fullsubopen + span {
        padding-right: 50px;
        margin-right: 0; }

.mm-menu > .mm-panel > .mm-listview {
  margin: 20px -20px; }

.mm-menu > .mm-panel > .mm-listview:first-child,
.mm-menu > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px; }

.mm-listview .mm-inset {
  list-style: inside disc;
  padding: 0 10px 15px 40px;
  margin: 0; }
  .mm-listview .mm-inset > li {
    padding: 5px 0; }

.mm-listview .mm-divider {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px; }

.mm-listview .mm-spacer {
  padding-top: 40px; }
  .mm-listview .mm-spacer > .mm-next {
    top: 40px; }
  .mm-listview .mm-spacer.mm-divider {
    padding-top: 25px; }

.mm-prev:before,
.mm-next:after,
.mm-arrow:after {
  content: '';
  border: 2px solid transparent;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 20px; }

.mm-next:after,
.mm-arrow:after {
  border-top: none;
  border-left: none;
  right: 20px; }

.mm-menu {
  background: #3c3c3c;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .mm-menu .mm-navbar > *,
  .mm-menu .mm-navbar a {
    color: #00a4ed; }
  .mm-menu .mm-navbar .mm-btn:before, .mm-menu .mm-navbar .mm-btn:after {
    border-color: #00a4ed; }
  .mm-menu .mm-listview {
    border-color: rgba(255, 255, 255, 0.1); }
    .mm-menu .mm-listview > li .mm-next:after,
    .mm-menu .mm-listview > li .mm-arrow:after {
      border-color: #00a4ed; }
    .mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
    .mm-menu .mm-listview > li.mm-selected > span {
      background: rgba(255, 255, 255, 0.1); }
  .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next,
  .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel,
  .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next,
  .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel {
    background: rgba(0, 0, 0, 0.05); }
  .mm-menu .mm-divider {
    background: rgba(0, 0, 0, 0.05); }

/*
	jQuery.mmenu offcanvas addon CSS
*/
.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  -webkit-transition: -webkit-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease; }

html.mm-opened {
  overflow: hidden;
  position: relative; }
  html.mm-opened body {
    overflow: hidden; }

html.mm-background .mm-page {
  background: inherit; }

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999; }

html.mm-opened #mm-blocker,
html.mm-blocking #mm-blocker {
  display: block; }

.mm-menu.mm-offcanvas {
  display: none;
  position: fixed; }

.mm-menu.mm-current {
  display: block; }

.mm-menu {
  width: 80%;
  min-width: 140px;
  max-width: 440px; }

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -moz-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  -o-transform: translate(80%, 0);
  transform: translate(80%, 0); }

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -moz-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    -o-transform: translate(140px, 0);
    transform: translate(140px, 0); } }

@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -moz-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    -o-transform: translate(440px, 0);
    transform: translate(440px, 0); } }

.mm-page {
  overflow: hidden; }

#main_menu_mobile:not(.mm-menu) {
  display: none; }

#mm-blocker {
  background-color: rgba(0, 0, 0, 0.6); }

.mm-title {
  font-weight: 700;
  color: #fff !important; }

.mm-navbar {
  background-color: #373737 !important; }
  .mm-navbar.mm-hasbtns {
    padding: 0 1.25rem !important; }

.mm-menu .category-thumbnail {
  display: none; }

.mm-close:after {
  content: "✖" !important;
  font-size: 25px; }

.mm-navbar-top-1 {
  background-color: #00a4ed !important;
  text-align: left !important; }
  .mm-navbar-top-1 .mm-close {
    color: #fff !important; }
  .mm-navbar-top-1 .-mod-languages a {
    padding: 0 !important;
    display: block !important; }

.mm-listview .item-142, .mm-listview .item-197 {
  font-weight: 700; }

.mm-navbar-bottom {
  padding: 0 !important; }
  .mm-navbar-bottom #social-menu {
    padding: 0 !important; }
    .mm-navbar-bottom #social-menu a {
      padding: 0.625rem 1.25rem; }
    .mm-navbar-bottom #social-menu svg {
      font-size: 1.5rem; }

#scrollUp {
  right: 0.9375rem;
  bottom: 0.9375rem;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  background-color: #00a4ed;
  color: #fff;
  transition: background-color .2s ease-in-out; }
  @media (max-width: 1199.98px) {
    #scrollUp {
      display: none !important; } }
  #scrollUp:hover {
    background-color: #0081ba; }

.r-tabs {
  /* Tabs container */
  position: relative;
  /* margin-top: 2rem;
   margin-bottom: -1rem;*/ }
  @media (min-width: 992px) {
    .r-tabs {
      margin-top: 0; } }
  .r-tabs .r-tabs-accordion-title {
    display: none;
    margin-bottom: 1rem;
    /* Accordion anchor */
    /* Active accordion button */ }
    @media (max-width: 767.98px) {
      .r-tabs .r-tabs-accordion-title {
        display: block; } }
    .r-tabs .r-tabs-accordion-title a {
      border: 1px solid #f2f2f2;
      position: relative;
      background-color: #fff;
      color: #777;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700; }
      .r-tabs .r-tabs-accordion-title a:after {
        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -1px;
        width: 14px;
        height: 2px;
        background-color: #00a4ed; }
      .r-tabs .r-tabs-accordion-title a:before {
        content: "";
        position: absolute;
        right: 16px;
        margin-top: -7px;
        top: 50%;
        width: 2px;
        height: 14px;
        background-color: #00a4ed; }
    .r-tabs .r-tabs-accordion-title.r-tabs-state-active {
      /* Active accordion anchor */ }
      .r-tabs .r-tabs-accordion-title.r-tabs-state-active a:before {
        display: none; }
      .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
        color: #000000; }
    .r-tabs .r-tabs-accordion-title .r-tabs-anchor {
      display: block;
      padding: 1rem; }
    .r-tabs .r-tabs-accordion-title.r-tabs-state-active {
      margin-bottom: 0; }
  .r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
    /* Tab element */
    /* Tab anchor */
    /* Active state tab anchor */ }
    @media (max-width: 991.98px) {
      .r-tabs .r-tabs-nav {
        display: none; } }
    .r-tabs .r-tabs-nav .r-tabs-tab {
      width: 100%;
      text-align: center; }
    .r-tabs .r-tabs-nav .r-tabs-anchor {
      height: 100%;
      text-decoration: none;
      padding: 1rem;
      transition: background-color 250ms ease-in-out;
      font-weight: 700;
      color: #777;
      text-transform: capitalize;
      border-bottom: 2px solid #f2f2f2;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .r-tabs .r-tabs-nav .r-tabs-anchor-secondary:hover {
        border-bottom-color: #e90081; }
      .r-tabs .r-tabs-nav .r-tabs-anchor-primary:hover {
        border-bottom-color: #00a4ed; }
    .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
      cursor: default; }
      .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor.-primary {
        color: #00a4ed;
        border-bottom-color: #00a4ed; }
      .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor.-secondary {
        color: #e90081;
        border-bottom-color: #e90081; }
  .r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none; }
  .r-tabs .r-tabs-panel {
    background-color: #fff;
    display: none;
    padding: 1rem 0; }
    @media (min-width: 768px) {
      .r-tabs .r-tabs-panel {
        padding: 1.5rem 0; } }
    .r-tabs .r-tabs-panel.r-tabs-state-active {
      display: block; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 10:00] -> A commenter si non utilisé */
.owl-carousel {
  list-style: none;
  position: relative; }

.owl-stage {
  display: flex !important; }

.owl-item {
  position: relative;
  float: none !important;
  text-align: center; }
  .owl-item > * {
    height: 100%; }
  .owl-item img {
    display: inline-block !important;
    width: auto; }
  .owl-item .fa-external-link {
    display: none !important; }

.owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: -1; }
  .owl-nav.disabled {
    display: none; }
  .owl-nav > button {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .owl-nav > button.disabled {
      opacity: .5; }
    .owl-nav > button:hover {
      cursor: pointer; }
      .owl-nav > button:hover svg {
        color: #00a4ed; }
    .owl-nav > button svg {
      transition: all 0.2s ease-in-out;
      width: 1.5625rem !important; }
    .owl-nav > button.owl-prev {
      left: -1.875rem; }
    .owl-nav > button.owl-next {
      right: -1.875rem; }

.owl-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.625rem; }
  .owl-dots.disabled {
    display: none; }
  .owl-dots .owl-dot {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #000000 !important;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
    background-color: transparent; }
    .owl-dots .owl-dot.active span {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background-color: #000000; }

@media (max-width: 767.98px) {
  .owl-carousel-sm-destroy {
    padding: 0 !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .owl-carousel-md-destroy {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .owl-carousel-lg-destroy {
    padding: 0 !important; } }

.owl-carousel.-owl-carousel-shadow .owl-item > * {
  padding: 0rem 1.25rem 1.875rem 1.25rem; }

@media (min-width: 768px) {
  .owl-carousel.-expanded {
    margin-left: -1.25rem; } }

@media (min-width: 768px) {
  .owl-carousel-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap; } }

.-mod-fav-festival .owl-stage, .owl-carousel.-autowidth .owl-stage {
  display: block !important; }

.-mod-fav-festival .owl-item, .owl-carousel.-autowidth .owl-item {
  float: left !important; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 10:00] -> A commenter si non utilisé */
[data-readmore] {
  position: relative; }
  [data-readmore]:after {
    position: absolute;
    content: '';
    display: block;
    height: 1.25rem;
    width: 100%;
    background: linear-gradient(rgba(242, 242, 242, 0) 0%, white 99%);
    left: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;
    opacity: 1; }
  [data-readmore]:not([aria-expanded]):after {
    opacity: 0; }
  [data-readmore][aria-expanded="true"]:after {
    opacity: 0; }
  [data-readmore] > :first-child {
    margin-top: 0 !important; }
  [data-readmore] > :last-child {
    margin-bottom: 0; }
  [data-readmore] p {
    margin: .6em 0; }
  [data-readmore] h1, [data-readmore] h2, [data-readmore] h3, [data-readmore] h4, [data-readmore] h5 {
    margin-top: .4em !important;
    margin-bottom: .2em !important; }
  [data-readmore] ul {
    margin: 1em 0 1em 1em; }

.btn-readmore {
  width: auto;
  display: inline-block;
  font-weight: 700;
  color: #3c3c3c;
  margin-top: 0.625rem; }

/* TODO[INTEG] by ukoo_alex [2016-11-07 10:00] -> A commenter si non utilisé */
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px;
  /*IE9 and below*/ }
  .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    text-transform: none;
    z-index: 1;
    text-align: right;
    white-space: nowrap; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
      color: #5e5e5e; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
      color: rgba(255, 255, 255, 0.5); }
  .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none; }
    .bootstrap-select > select.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2; }
  .has-error .bootstrap-select .dropdown-toggle,
  .error .bootstrap-select .dropdown-toggle,
  .bootstrap-select.is-invalid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
    border-color: #dc3545; }
  .bootstrap-select.is-valid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
    border-color: #28a745; }
  .bootstrap-select.fit-width {
    width: auto !important; }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%; }
  .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }
  :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%; }
  .bootstrap-select.form-control.input-group-btn {
    z-index: auto; }
    .bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
      border-radius: 0; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }
  .form-group-lg .bootstrap-select.form-control .dropdown-toggle,
  .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }
  .bootstrap-select.disabled:focus,
  .bootstrap-select > .disabled:focus {
    outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }
  .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060; }

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100% !important;
  box-sizing: border-box;
  box-shadow: 0 0.9375rem 2.1875rem rgba(0, 0, 0, 0.05);
  max-width: 258px; }
  .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important; }
  .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none; }
  .bootstrap-select .dropdown-menu .dropdown-item {
    white-space: inherit; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li.active small {
      color: #fff; }
    .bootstrap-select .dropdown-menu li.active a {
      border-color: #00a4ed !important; }
    .bootstrap-select .dropdown-menu li.disabled a {
      cursor: not-allowed; }
    .bootstrap-select .dropdown-menu li:last-child a {
      border-bottom: none !important; }
    .bootstrap-select .dropdown-menu li a {
      cursor: pointer;
      user-select: none;
      border-bottom: 2px solid #e6e6e6 !important; }
      .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em; }
      .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none; }
      .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block; }
    .bootstrap-select .dropdown-menu li small {
      padding-left: 0.5em; }
  .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 0.375rem 0.9375rem;
  border-radius: 0.2rem;
  background: #f2f2f2;
  margin: 0.3125rem 0.3125rem 0 0.3125rem; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #3c3c3c;
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid #3c3c3c;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 0.625rem 0.9375rem; }

.bs-actionsbox {
  width: 100%;
  box-sizing: border-box; }
  .bs-actionsbox .btn-group button {
    width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box; }
  .bs-donebutton .btn-group button {
    width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

/*# sourceMappingURL=template.css.map */